import React, { PureComponent } from "react";
import Swiper from "react-id-swiper";
import Header from "./header/Header";
import "./styles/swiper.css";

class Acc01 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const params = {
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next.swiper-button-white",
        prevEl: ".swiper-button-prev.swiper-button-white"
      },
      spaceBetween: 0,
      noSwiping: true,
      //slidesPerView: 1,
      loop: true,
      // autoplay: {
      //   delay: 6000
      // },
      effect: "slide"
    };
    return (
      <div className="site">
        <Header />

        <div className="main">
          <div className="thecell x50 s-x100 mediaContent">
            <iframe
              title="onrunning"
              src="https://badvisor.badbyte.ch/hosted/?id=salar"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen={true}
              style={{ height: "100%", width: "100%" }}
              frameBorder="0"
            />
          </div>

          <div
            className="thecell x50 s-x100 mainContent"
            style={{
              backgroundColor: "#000",
              color: "#fff"
            }}
          >
            <div
              className="thecell padding "
              style={{ backgroundColor: "#fff", color: "#000" }}
            >
              <div className="thecell padding" style={{ maxWidth: "33rem" }}>
                <h1>
                  SALAR /<br /> Handbag
                </h1>

                <br />
              </div>
            </div>

            <div className="thecell padding ">
              <div className="thecell padding" style={{ maxWidth: "33rem" }}>
                <h1>
                  "Until the 16th century, the web was believed to be flat"
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Acc01;
