import React, { PureComponent } from "react";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";

var current = null;

class DrawerList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handelize(str) {
    return str
      .toLowerCase()
      .replace(/[^\w\u00C0-\u024f]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }

  handleClick = e => {
    this.setState({
      [current]: false,
      [e]: !this.state[e]
    });
    current = e;
  };

  render() {
    return (
      <div className="drawerlist">
        <List>
          <ListItem button key="Home" component="a" href="/">
            <ListItemText>
              <strong>HOME</strong>
            </ListItemText>
          </ListItem>

          <ListItem button key="D01" component="a" href="/d01">
            <ListItemText>
              <strong>DESIGN</strong>
            </ListItemText>
          </ListItem>
          <ListItem button key="ART01" component="a" href="/art01">
            <ListItemText>
              <strong>ART</strong>
            </ListItemText>
          </ListItem>
          <ListItem button key="F01" component="a" href="/f01">
            <ListItemText>
              <strong>FASHION</strong>
            </ListItemText>
          </ListItem>

          <ListItem button key="ACC01" component="a" href="/acc01">
            <ListItemText>
              <strong>ACCESSORIES</strong>
            </ListItemText>
          </ListItem>

          <ListItem button key="STR01" component="a" href="/str01">
            <ListItemText>
              <strong>SNEAKERS</strong>
            </ListItemText>
          </ListItem>

          <ListItem button key="RUN01" component="a" href="/run01">
            <ListItemText>
              <strong>RUNNING</strong>
            </ListItemText>
          </ListItem>

          <ListItem button key="furn01" component="a" href="/furn01">
            <ListItemText>
              <strong>FURNITURE</strong>
            </ListItemText>
          </ListItem>

          <ListItem
            button
            key="STR01"
            component="a"
            href="mailto:info@badbyte.ch"
          >
            <ListItemText>
              <strong>CONTACT</strong>
            </ListItemText>
          </ListItem>
        </List>
      </div>
    );
  }
}

export default DrawerList;
