import React, { PureComponent } from "react";
import Swiper from "react-id-swiper";
import Header from "./header/Header";
import "./styles/swiper.css";

class Art01 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const params = {
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next.swiper-button-white",
        prevEl: ".swiper-button-prev.swiper-button-white"
      },
      spaceBetween: 0,
      noSwiping: true,
      //slidesPerView: 1,
      loop: true,
      // autoplay: {
      //   delay: 6000
      // },
      effect: "slide"
    };
    return (
      <div className="site">
        <Header />

        <div className="main">
          <div className="thecell x50 s-x100 mediaContent">
            <iframe
              title="horse"
              src="https://badvisor.badbyte.ch/hosted/?id=horse"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen={true}
              style={{ height: "100%", width: "100%" }}
              frameBorder="0"
            />
          </div>
          <div
            className="thecell x50 s-x100 mainContent"
            style={{
              backgroundColor: "#000",
              color: "#fff"
            }}
          >
            <div
              className="thecell padding "
              style={{ backgroundColor: "#fff", color: "#000" }}
            >
              <div className="thecell padding" style={{ maxWidth: "33rem" }}>
                <h1>Nag Arnoldi / Cavallo 1977</h1>
              </div>
            </div>
            <div className="thecell padding ">
              <div className="thecell padding" style={{ maxWidth: "33rem" }}>
                <h1>
                  "Until the 16th century, the web was believed to be flat"
                </h1>
              </div>
            </div>
            <div className="sliderOverlay">
              <Swiper {...params}>
                <div>
                  <div className="image">
                    <img src="/assets/cavallo2.jpg" />
                  </div>
                </div>
                <div>
                  <div className="image">
                    <img src="/assets/cavallo1.jpg" />
                  </div>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// <Swiper {...params}>
//   <div>
//     <iframe
//       title="horse"
//       src={`/frame/?slug=horse`}
//       webkitallowfullscreen="true"
//       mozallowfullscreen="true"
//       allowFullScreen={true}
//       style={{ height: "100%", width: "100%" }}
//       frameBorder="0"
//     />
//   </div>
//   <div>
//     <div className="image">
//       <img src="/assets/cavallo1.jpg" />
//     </div>
//   </div>
//   <div>
//     <div className="image">
//       <img src="/assets/cavallo2.jpg" />
//     </div>
//   </div>
// </Swiper>

export default Art01;
