import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PersonIcon from "@material-ui/icons/Person";
import MenuIcon from "@material-ui/icons/Menu";
import SimpleMenuList from "./components/SimpleMenuList";
import Drawer from "@material-ui/core/Drawer";
import DrawerList from "./components/DrawerList";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";

import Button from "@material-ui/core/Button";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: 12,
    marginRight: -12
  }
};

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      anchorEl: null
    };
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      right: open
    });
  };

  // async fetchHeader() {
  //   const response = await fetch(
  //     `https://badbyte.ch/cms/api/singletons/get/header`,
  //     {
  //       method: "post",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Cockpit-Token": "189f6f339371195fcccbd85236fdee"
  //       },
  //       body: JSON.stringify({
  //         lang: this.props.locale,
  //         populate: 6
  //         // fields: { name: 1, url_handle: 1 }
  //       })
  //     }
  //   );
  //   if (!response.ok) {
  //     return;
  //   }
  //   const jsonData = await response.json();
  //   sessionStorage.setItem(
  //     `header_${this.props.locale}`,
  //     JSON.stringify(jsonData)
  //   );
  //
  //   this.setState({
  //     header: jsonData
  //   });
  // }

  // async componentDidMount() {
  //   this.setState({
  //     fetching: true
  //   });
  //   const cachedHeader = sessionStorage.getItem(`header_${this.props.locale}`);
  //
  //   if (cachedHeader) {
  //     this.setState({
  //       header: JSON.parse(cachedHeader),
  //
  //       fetching: false
  //     });
  //   }
  //   await this.fetchHeader();
  //
  //   this.setState({
  //     fetching: false
  //   });
  // }

  // async componentDidUpdate(prevProps) {
  //   if (this.props.url !== prevProps.url) {
  //     this.setState({
  //       right: false
  //     });
  //     const cachedHeader = sessionStorage.getItem(
  //       `header_${this.props.locale}`
  //     );
  //
  //     if (cachedHeader) {
  //       this.setState({
  //         header: JSON.parse(cachedHeader)
  //       });
  //     }
  //     await this.fetchHeader();
  //   }
  // }

  render() {
    const { classes } = this.props;

    return (
      <div className="header">
        <div className={classes.root}>
          <AppBar position="fixed" className="appbar">
            <Toolbar>
              <div className={classes.grow}>
                <Link to="/" title="BADBYTE" className="sitelabel">
                  <h5
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      paddingLeft: "1rem",
                      color: "#000"
                    }}
                  >
                    BADBYTE
                  </h5>
                </Link>
              </div>

              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
                onClick={this.toggleDrawer("right", true)}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </div>
        <Drawer
          anchor="right"
          open={this.state.right}
          onClose={this.toggleDrawer("right", false)}
        >
          <div
            tabIndex={0}
            style={{
              height: "100%",
              overflow: "auto",
              paddingBottom: "2rem"
            }}
            role="button"
          >
            <DrawerList />
          </div>
        </Drawer>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);
