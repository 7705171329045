import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Header from "./header/Header";
class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="site homesite">
        <Header />
        <div className="home">
          <div style={{ maxWidth: "460px" }}>
            <h1
              style={{
                padding: "0 1rem",
                backgroundColor: "#000000",
                color: "#ffffff"
              }}
            >
              BADBYTE
            </h1>
            <br />
            <br />

            <h3>
              <a href="/d01">Design</a>
            </h3>
            <span style={{ display: "none" }}>Experience.</span>
            <span style={{ display: "initial" }}>01</span>
            <br />

            <h3>
              <a href="/art01">Art</a>
            </h3>
            <span style={{ display: "none" }}>Share.</span>
            <span style={{ display: "initial" }}>02</span>
            <br />

            <h3>
              <a href="/f01">Fashion</a>
            </h3>
            <span style={{ display: "none" }}>Engage.</span>
            <span style={{ display: "initial" }}>03</span>
            <br />

            <h3>
              <a href="/acc01">Accessories</a>
            </h3>
            <span style={{ display: "none" }}>Soul.</span>
            <span style={{ display: "initial" }}>04</span>
            <br />

            <h3>
              <a href="/str01">Sneakers</a>
            </h3>
            <span style={{ display: "none" }}>Soul.</span>
            <span style={{ display: "initial" }}>05</span>
            <br />

            <h3>
              <a href="/run01">Running</a>
            </h3>
            <span style={{ display: "none" }}>Soul.</span>
            <span style={{ display: "initial" }}>06</span>
            <br />

            <h3>
              <a href="/furn01">Furniture</a>
            </h3>
            <span style={{ display: "none" }}>Engage.</span>
            <span style={{ display: "initial" }}>07</span>
            <br />

            <br />
            <br />
            <span style={{ position: "absolute", bottom: "40px" }}>
              <a href="mailto:info@badbyte.ch">Need answers?</a>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
