import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "./styles/antimess.css";
import { Route, Switch } from "react-router-dom";
import Design01 from "./Design01";
import Art01 from "./Art01";
import Street01 from "./Street01";
import Fashion01 from "./Fashion01";
import Furniture01 from "./Furniture01";
import Run01 from "./Run01";
import Acc01 from "./Acc01";

import Home from "./Home";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/d01" exact component={Design01} />
        <Route path="/art01" exact component={Art01} />
        <Route path="/str01" exact component={Street01} />
        <Route path="/f01" exact component={Fashion01} />
        <Route path="/run01" exact component={Run01} />
        <Route path="/acc01" exact component={Acc01} />
        <Route path="/furn01" exact component={Furniture01} />
      </Switch>
    </div>
  );
}

export default App;
